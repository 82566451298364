import {
  type AdminPersonProfileRes,
  type PlayerProfileResponse,
} from '@on3/api';
import { createContext, useContext, useState } from 'react';
import { type AthleteType, getAthleteType } from 'utils/player';

export interface IPlayerState {
  player: PlayerProfileResponse;
  adminPlayer?: AdminPersonProfileRes | null;
}

interface IPlayerContext {
  state: IPlayerState & { playerType: AthleteType };
  setState: React.Dispatch<React.SetStateAction<IPlayerState>>;
}

const PlayerContext = createContext<IPlayerContext>({} as IPlayerContext);

export const PlayerProvider = ({
  value = {
    player: {} as PlayerProfileResponse,
    adminPlayer: {} as AdminPersonProfileRes,
  },
  children,
}: {
  value: IPlayerState;
  children: React.ReactNode;
}) => {
  const [state, setState] = useState<IPlayerState>({
    ...value,
  });

  return (
    <PlayerContext.Provider
      value={
        {
          state: { ...state, playerType: getAthleteType(state.player) },
          setState,
        } as IPlayerContext
      }
    >
      {children}
    </PlayerContext.Provider>
  );
};

export const usePlayerProfile = () => {
  const context = useContext(PlayerContext);

  return context;
};
